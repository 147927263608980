// 组件
import importImg from '@/components/importImg.vue'
// 接口
import * as shopsApi from '@/services/shops.js'
import * as shopItemsApi from '@/services/shop-items.js'
import * as shopItemStocksApi from '@/services/shop-item-stocks.js'
import { getWholesalePricingTiers } from '@/services/product.js'
// 其他
import dataDictionary from '@/utils/data-dictionary.js'
import constant from '@/utils/constant.js'
import verifyRules from '@/utils/verify-rules/index.js'
import loading from '@/utils/loading.json'
import Utils from '@/utils/Utils.js'

export default {
  components: {
    importImg
  },
  data () {
    return {
      app_name: process.env.VUE_APP_NAME,
      constant,
      dataDictionary,
      verifyRules: verifyRules(this),
      shopList: [],
      shopItemList: [],
      wholesalePrice: [],
      currProduct: null,
      mainForm: {
        shopSelect: {
          disabled: false,
          loading: false
        },
        shopItemSelect: {
          disabled: false,
          loading: false
        },
        data: {
          online: true,
          shop_id: null,
          shop_item_id: null,
          name: null,
          name_en: null,
          sku_id: null,
          price_f: null,
          cost_price_f: null,
          line_price_f: null,
          host_commission_percent: null,
          direct_delivery_host_commission: null,
          remote_image_url: null,
          saling_stock: null,
          warning_stock: null
          // inventory_quantity: 1
        }
      }
    }
  },
  create () {
    if (this.app_name !== 'fooyo') {
      Object.assign(this.mainForm.data, {
        inventory_quantity: 1
      })
    }
    if (this.app_name === 'combine') {
      Object.assign(this.mainForm.data, {
        zone: null,
        section: null,
        bin: null,
        support_wholesale: false,
        tierX_1: null
      })
    }
  },
  mounted () {
    const _this = this
    _this.init()
  },
  methods: {
    init () {
      const _this = this
      const initRequest1 = shopsApi.getShopMap().then(res => {
        _this.shopList = res.data.shops
      }).catch(() => {})
      const requestArr = [initRequest1]
      if (this.app_name === 'combine') {
        const initRequest2 = getWholesalePricingTiers().then(res => {
          _this.wholesalePrice = res.data.wholesale_pricing_tiers
          return res
        }).catch(err => err)
        requestArr.push(initRequest2)
        _this.setGlobalLoading(true)
        Promise.all(requestArr).then(() => {
          _this.setGlobalLoading(false)
        }).catch(() => {
          _this.setGlobalLoading(false)
        })
      }
    },
    handleChange (data, prop) {
      if (prop === 'name' && data !== '') {
        this.verifyRules.goodsManagerVariationsAdd.mainForm.name[0].required = false
      }
      if ((prop === 'name') && this.mainForm.data.name === '') {
        this.verifyRules.goodsManagerVariationsAdd.mainForm.name[0].required = true
      }
      if (prop === 'name_en' && data !== '') {
        this.verifyRules.goodsManagerVariationsAdd.mainForm.name_en[0].required = false
      }
      this.$nextTick(() => {
        this.$refs.mainForm.clearValidate()
      })
    },
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    handleMainFormShopSelectChange (val) {
      const _this = this
      _this.shopItemList = []
      _this.mainForm.data.shop_item_id = null
      _this.mainForm.shopItemSelect.loading = true
      shopItemsApi.getShopItemByShopList({
        shop_id: val
      }).then(res => {
        _this.mainForm.shopItemSelect.loading = false
        _this.shopItemList = res.data.item_type_list
      }).catch(() => {
        _this.mainForm.shopItemSelect.loading = false
      })
    },
    handleMainFormImgChange (fileData) {
      const _this = this
      if (fileData && fileData.length > 0) {
        _this.mainForm.data.remote_image_url = fileData[0].url
      } else {
        _this.mainForm.data.remote_image_url = null
      }
    },
    handleMainFormCancelClick () {
      const _this = this
      _this.$router.go(-1)
    },
    handleMainFormSubmitClick () {
      const _this = this
      _this.$refs.mainForm.validate((valid) => {
        if (valid) {
          let requestParams = {}
          Object.assign(requestParams, _this.mainForm.data)
          if (this.app_name === 'combine') {
            if (requestParams.support_wholesale) {
              requestParams.shop_item_stock_tier_prices_attributes = this.wholesalePrice.map(tier => {
                if (tier.price_f || tier.price_f === 0) {
                  return {
                    wholesale_pricing_tier_id: tier.id,
                    price_f: tier.price_f
                  }
                }
              })
            }
            delete requestParams.tierX_1
          }
          requestParams = {
            shop_item_stock: {
              ...requestParams
            }
          }
          _this.setGlobalLoading(true)
          shopItemStocksApi.postShopItemStocks(requestParams).then(() => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            setTimeout(() => {
              _this.$router.push({
                name: 'goodsManagerVariationsList'
              })
            }, constant.ROUTER_CHANGE_INTERVAL)
          }).catch(() => _this.setGlobalLoading(false))
        } else {
          this.$message.error(this.$t('message.formIsRequire'))
          return false
        }
      })
    },
    handleStocksChange (data, prop, tIndex = -1) {
      if (tIndex === 0) {
        this.mainForm.data.tierX_1 = data
        if (prop === 'tierX_1') {
          if (!Utils.isNull(data)) {
            this.verifyRules.goodsManagerVariationsAdd.mainForm.tierX_1[0].required = false
          }
          if (Utils.isNull(this.mainForm.data.tierX_1)) {
            this.verifyRules.goodsManagerVariationsAdd.mainForm.tierX_1[0].required = true
          }
        }
      }
      this.$nextTick(() => {
        this.$refs.mainForm.clearValidate()
      })
    },
    shopItemChange (val) {
      this.currProduct = this.shopItemList.find(item => {
        return item.id === val
      })
    }
  }
}
