<template>
  <div>
    <el-card>
      <el-form ref="mainForm" :model="mainForm.data" :rules="verifyRules.goodsManagerVariationsAdd.mainForm" label-width="160px">
        <el-row :gutter="12">
          <el-col :md="24" :lg="6">
            <el-form-item :label="$t('form.product.online')" prop="online">
              <el-switch v-model="mainForm.data.online"> </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="12">
          <el-col :md="24" :lg="8">
            <!-- 商铺 -->
            <el-form-item :label="$t('form.common.shop')" prop="shop_id">
              <el-select
                v-model="mainForm.data.shop_id"
                class="width-fill"
                :placeholder="$t('placeholder.defaultSelect')"
                clearable
                filterable
                :disabled="mainForm.shopSelect.disabled"
                :loading="mainForm.shopSelect.loading"
                @change="handleMainFormShopSelectChange"
              >
                <el-option v-for="(item, index) in shopList" :key="index" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- 商品 -->
            <el-form-item :label="$t('text.product')" prop="shop_item_id">
              <el-select
                v-model="mainForm.data.shop_item_id"
                class="width-fill"
                :placeholder="$t('placeholder.defaultSelect')"
                clearable
                filterable
                value-key="item"
                :disabled="mainForm.shopItemSelect.disabled"
                :loading="mainForm.shopItemSelect.loading"
                @change="shopItemChange"
              >
                <el-option v-for="(item, index) in shopItemList" :key="index" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- 中文名称 -->
            <el-form-item :label="$t('form.product.cnName')" prop="name">
              <el-input
                @change="(data) => { return handleChange(data, 'name') }"
                v-model="mainForm.data.name"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="12">
          <el-col :md="24" :lg="8">
            <!-- 英文名称 -->
            <el-form-item :label="$t('form.product.enName')" prop="name_en">
              <el-input
                @change="(data) => { return handleChange(data, 'name_en') }"
                v-model="mainForm.data.name_en"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- SKU ID -->
            <el-form-item :label="$t('form.product.sku')" prop="sku_id">
              <el-input
                v-model="mainForm.data.sku_id"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- 销售价格（元） -->
            <el-form-item :label="$t('form.product.sellingPrice')" prop="price_f">
              <el-input
                v-model="mainForm.data.price_f"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="12">
          <el-col :md="24" :lg="8">
            <!-- 划线价（元） -->
            <el-form-item :label="$t('form.product.linePrice')" prop="line_price_f">
              <el-input
                v-model="mainForm.data.line_price_f"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
            <el-col :md="24" :lg="8">
            <!-- 成本价（元） -->
            <el-form-item :label="$t('form.product.costPrice')" prop="cost_price_f">
              <el-input
                v-model="mainForm.data.cost_price_f"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- 团长提成 (%) -->
            <el-form-item :label="$t('form.product.headCommission')" prop="host_commission_percent">
              <el-input
                v-model="mainForm.data.host_commission_percent"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <el-form-item :label="$t('form.product.directDeliveryHostCommission')" prop="direct_delivery_host_commission">
              <el-input
                v-model="mainForm.data.direct_delivery_host_commission"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="12">
          <el-col :md="24" :lg="8">
            <!-- 图片 -->
            <el-form-item :label="$t('form.product.image')" prop="remote_image_url">
              <importImg :limit="1" @change="handleMainFormImgChange" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="12">
          <el-col :md="24" :lg="8">
            <!-- 可销售库存 -->
            <el-form-item :label="$t('form.product.slaingStock')" prop="saling_stock">
              <el-input
                v-model="mainForm.data.saling_stock"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- 预警库存 -->
            <el-form-item :label="$t('form.product.warningStock')" prop="warning_stock">
              <el-input
                v-model="mainForm.data.warning_stock"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8" v-if="app_name !== 'fooyo'">
            <!-- 锁定库存 -->
            <el-form-item :label="$t('form.product.lockingStock')" prop="inventory_quantity">
              <el-input
                v-model="mainForm.data.inventory_quantity"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="12" v-if="app_name === 'combine'">
          <el-col :md="24" :lg="8">
            <!-- zone -->
            <el-form-item :label="$t('form.product.zone')" prop="zone">
              <el-input
                v-model="mainForm.data.zone"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- section -->
            <el-form-item :label="$t('form.product.section')" prop="section">
              <el-input
                v-model="mainForm.data.section"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- bin -->
            <el-form-item :label="$t('form.product.bin')" prop="bin">
              <el-input
                v-model="mainForm.data.bin"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row v-if="app_name === 'combine'" >
          <el-col :md="24" :lg="8">
            <el-form-item :label="$t('form.product.supportWholesale')" prop="support_wholesale">
              <el-switch v-model="mainForm.data.support_wholesale"> </el-switch>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row v-if="app_name === 'combine' && currProduct && currProduct.support_wholesale">
          <el-col :md="24" :lg="24">
            <el-form-item :label="$t('form.product.wholesalePrice')" :prop="`tierX_1`">
              <ul class="wholesale">
                <li
                v-for="(tier, tIndex) in wholesalePrice"
                :key="tIndex"
                >
                  <div class="boder-b">{{tier.name}}</div>
                  <div>
                    <el-input
                    @change="(data) => { return handleStocksChange(data, 'tierX_1', tIndex) }"
                    v-model="tier.price_f"
                    type="number"
                    :placeholder="$t('placeholder.defaultInput')"
                    ></el-input>
                  </div>
                </li>
              </ul>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row type="flex" justify="center">
          <el-button @click="handleMainFormCancelClick">{{ $t('button.cancel') }}</el-button>
          <el-button type="primary" @click="handleMainFormSubmitClick">{{ $t('button.confirm') }}</el-button>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import Logic from './index.js'
export default Logic
</script>

<style lang="scss" scope>
@import './index.scss';
</style>
